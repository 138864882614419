:root {
	--navbarHeight: 50px;
	--headerHeight: 100px;
	--main: rgb(53, 53, 53);
	--navbar: #3dbdc2;
	--fact: rgb(135, 255, 124);
	--factHover: #3dbdc2;
}

body {
	background-color: var(--main);
}

.firstView {
	width: 100vw;
	height: calc(100vh - var(--navbarHeight));
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(10px);
	z-index: 2;
}

.firstViewBox {
	width: 80%;
	background: var(--navbar);
	padding: 20px 20px 20px 40px;
	border: 2px solid black;
	border-radius: 5px;
	line-height: 200%;
	height: fit-content;
	max-height: 80%;
	overflow: scroll;
}

.firstViewMessage {
	font-size: 160%;
	font-family: 'Comfortaa', cursive;
}

.navbarContainer {
	height: var(--navbarHeight);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--navbar);
}

.navbarLink {
	color: var(--main);
	font-size: 150%;
	text-decoration: none;
	margin: 20px 0px 20px 20px;
	padding: 3px 5px 3px 5px;
}

.navbarLinkSelected {
	color: var(--navbar);
	background-color: var(--main);
	padding: 3px 5px 3px 5px;
	border-radius: 3px;
}

.homePageContainer {
	width: 100vw;
	height: calc(100vh - var(--navbarHeight));
	display: flex;
	flex-direction: column;
	align-items: center;
}

.headerContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80vw;
	min-height: var(--headerHeight);
}

.didYouKnowTextBig {
	color: var(--factHover);
	font-size: 700%;
	font-family: 'Monoton', cursive;
	font-style: italic;
	position: fixed;
	margin: 250px 20px 20px 0px;
}

.didYouKnowTextSmall {
	color: var(--factHover);
	font-size: 400%;
	font-family: 'Monoton', cursive;
	font-style: italic;
	position: fixed;
	margin: 20px 20px 10px 0px;
}

.iconContainer {
	margin: 0px 0px 0px auto;
}

.icon {
	padding: 5px;
	margin: 10px;
	font-size: 130%;
	border-radius: 3px;
	color: var(--factHover);
}

.icon:hover {
	cursor: pointer;
}

.iconSelected {
	color: var(--main);
	background: var(--factHover);
}

.allFactsContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: scroll;
	padding: 10px 20px 20px 20px;
}

.shuffleModeContainer {
	height: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.factContainer {
	width: 80vw;
	min-height: 200px;
	margin: 0px 20px 20px 20px;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid black;
	border-radius: 5px;
	animation: shrink 200ms forwards ease-in;
	font-size: 250%;
	font-family: 'Comfortaa', cursive;
	text-align: center;
	line-height: 150%;
	overflow: scroll;
}

.factContainer:hover {
	cursor: pointer;
	animation: grow 200ms forwards ease-in;
}

@keyframes grow {
	from {
		transform: scale(1);
		filter: brightness(1);
	}
	to {
		transform: scale(1.05);
		filter: brightness(1.2);
	}
}

@keyframes shrink {
	from {
		transform: scale(1.05);
		filter: brightness(1.2);
	}
	to {
		transform: scale(1);
		filter: brightness(1);
	}
}

.fullFactPage {
	width: 100vw;
	height: calc(100vh - var(--navbarHeight));
	display: flex;
	z-index: 1;
	position: absolute;
	animation: blur 100ms forwards ease-out;
}

@keyframes blur {
	from {
		backdrop-filter: blur(0px);
	}
	to {
		backdrop-filter: blur(10px);
	}
}

.fullFactContainer {
	width: 100vw;
	height: calc(100% - 60px);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px;
}

.fullFactBox {
	width: 80%;
	height: fit-content;
	max-height: 100%;
	animation: appear 300ms forwards ease-out;
	border-radius: 5px;
	border: 2px solid black;
	box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.5);
	padding: 10px;
	display: flex;
	flex-direction: column;
	overflow: scroll;
}

@keyframes appear {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.closeIcon {
	font-size: 300%;
	color: white;
	position: absolute;
	right: 40px;
	top: 30px;
	margin: 5px;
}

.closeIcon:before {
	content: '\00d7';
}

.closeIcon:hover {
	cursor: pointer;
}

.shortInfo {
	font-size: 200%;
	font-family: 'Comfortaa', cursive;
	text-align: center;
	line-height: 150%;
	margin: 20px 10px 30px 10px;
}

.fullInfo {
	font-size: 150%;
	font-family: 'Comfortaa', cursive;
	line-height: 150%;
	margin: 10px 10px 40px 10px;
}

.source {
	font-size: 150%;
	font-family: 'Comfortaa', cursive;
	color: blue;
	text-decoration: none;
	margin: auto 10px 10px auto;
}

/*-------------------------------------- Resources Submenu */

.resourcesPage {
	width: 100vw;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: center;
}

.resourcesSubMenu {
	width: 30%;
	height: 3vh;
	display: flex;
	justify-content: center;
	margin: 30px 0px 20px 0px;
	padding-bottom: 5px;
	border-bottom: 2px solid var(--factHover);
}

.resourcesSubMenuItem {
	color: rgb(184, 184, 184);
	margin: 0px 25px 0px 25px;
	font-size: 130%;
	white-space: nowrap;
}
.resourcesSubMenuItem:hover {
	cursor: pointer;
	color: var(--factHover);
}

.resourcesSubMenuSelected {
	color: var(--factHover);
}

/*-------------------------------------- Links Page */

.linksPage {
	width: 100vw;
	height: calc(100vh - var(--navbarHeight) - 3vh - 50px - 20px);
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Comfortaa', cursive;
	line-height: 140%;
	overflow: scroll;
}

.sectionContainer {
	width: 50%;
	display: flex;
	flex-direction: column;
	background: rgb(39, 39, 39);
	margin: 0px 0px 20px 0px;
	padding: 20px 20px 10px 20px;
	border-radius: 10px;
}

.heading {
	font-size: 170%;
	color: var(--factHover);
}

.links {
	font-size: 100%;
	color: white;
	text-decoration: none;
	margin: 10px 10px 0px 40px;
}

.bibliographySectionContainer {
	width: 50%;
	display: flex;
	flex-direction: column;
	background: rgb(39, 39, 39);
	margin: 0px 0px 20px 0px;
	padding: 20px;
	border-radius: 10px;
}

.bibliographySources {
	font-size: 100%;
	color: var(--factHover);
}
